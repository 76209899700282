.coming-soon-page-wrapper {
    width: auto; /* Take full view width */
    height: var(--app-height); /* Take full view height */
    overflow: hidden;
    background-image: url(../../Assets/soon.webp);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--primary-background-color);
}

.coming-soon-bg-video {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.logo-svg-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 60%;
}

.coming-soon-container {
    position: absolute;
    top: 0;
    width: 42vw;
    height: 38vw;
    margin-left: 29vw;
    margin-right: 29vw;
}

@media (max-width: 950px) {
    .coming-soon-container {
        width: 80vw;
        height: 80vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

.kizu-letter {
    outline: none !important;
    outline-color: transparent !important;
}

.coming-soon-letter-k{
    position: absolute; /* Position them absolutely to overlap */
    width: 100%; /* Full container width */
    height: 100%; /* Full container height */
}

.coming-soon-letter-i{
    position: absolute; /* Position them absolutely to overlap */
    width: 100%; /* Full container width */
    height: 100%; /* Full container height */
}

.coming-soon-letter-z {
    position: absolute; /* Position them absolutely to overlap */
    width: 100%; /* Full container width */
    height: 100%; /* Full container height */
}

.coming-soon-letter-u {
    position: absolute; /* Position them absolutely to overlap */
    width: 100%; /* Full container width */
    height: 100%; /* Full container height */
}

.coming-soon-text-container {
    position: absolute;
    bottom: 0;
    right: 15%;
    width: 20%;
    height: auto;
    display: flex;
    justify-content: right;
    align-items: right;

}

.coming-soon-text {
    position: relative;
    bottom: 0;
    right: 0;
    user-select: none;
}

.coming-soon-mobile-footer {
    display: none;
}

@media (max-width: 950px) {
    .coming-soon-mobile-footer {
        display: flex;
        position: absolute;
        bottom: 0;
        width: 95%;
        height: 10%;
        justify-content: center;
        font-size: large;
    }
}