
:root {
    --primary-color: #9058A0;
    --secondary-color: #b788c4;
    --transparent-color: rgba(183, 136, 196, 0.5);
    --primary-background-color: #6a3877;
    --secondary-background-color: #b788c4;
    --primary-haze-color: #2F2259;
    --secondary-haze-color: #7b69af;
    --secondary-haze-color-transparent: rgba(123, 105, 175, 0.5);
    --primary-kai-color: #486750;
    --secondary-kai-color: #9caca1;
    --secondary-kai-color-transparent: rgba(156, 172, 161, 0.5);
    --primary-miyako-color: #b54e69;
    --secondary-miyako-color: #fc97b1;
    --secondary-miyako-color-transparent: rgba(252, 151, 177, 0.5);
    --primary-zero-color: #1b1b1b;
    --secondary-zero-color: #ae1315;
    --secondary-zero-color-transparent: rgba(174, 19, 21, 0.5);
    --swiper-theme-color: white !important;
    --swiper-pagination-bullet-inactive-color: white !important;
    --header-scale: 100%;
    --header-background-color: #9058A0; /* this changes */
    --header-secondary-color: #b788c4; /* this changes */
    --icon-color: #9058A0; /* this changes */
    --icon-color-secondary: #b788c4; /* this changes */
    --icon-color-transparent: rgba(183, 136, 196, 0.4); /* this changes */
  }