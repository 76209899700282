.team-page-desktop-wrapper {
    /*   background-image: url("./Assets/test2.jpg");
      background-size: auto calc(100 * var(--app-height) / 100);
      background-repeat: no-repeat;
      background-position: center center; */
    background-color: var(--icon-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: calc(100 * var(--app-height) / 100);
    width: 100vw;
    z-index: 0;
}

.team-bg-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.team-page-desktop-group {
    z-index: 2;
}

.team-page-desktop-overlap {
    background-position: 50% 50%;
    background-size: cover;
    height: 1024px;
    position: relative;
}

.team-header {
    text-transform: uppercase;
    left: 2.5vw;
    position: absolute;
    top: calc(2.5 * var(--app-height) / 100);
    white-space: nowrap;
    color: var(--icon-color);
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(4px) brightness(100%);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.team-text-group {
    height: calc(65 * var(--app-height) / 100);
    left: 5vw;
    position: absolute;
    top: calc(20 * var(--app-height) / 100);
    ;
    width: 40vw;
    grid-template-columns: auto auto;
    grid-auto-columns: auto;
}

.team-text-grid-item {
    width: 19vw;
    height: calc(19 * var(--app-height) / 100);
}

.team-text-header {
    color: white;
    font-family: inherit;
    font-size: calc(3 * var(--app-height) / 100);
    font-weight: 400;
    padding-left: 2.5vw;
    /*   left: 7.5%; */
    letter-spacing: 0;
    /* line-height: 18px; */
    position: relative;
    top: 10%;
    height: 20%;
    width: 85%;
    white-space: pre-wrap;
}

.team-text-body {
    color: white;
    font-family: inherit;
    font-size: calc(1.25 * var(--app-height) / 100);
    font-weight: 400;
    padding-left: 2.5vw;
    /*   left: 7.5%; */
    letter-spacing: 0;
    /* line-height: 18px; */
    position: relative;
    top: 15%;
    width: 85%;
    height: 250px;
    max-height: 33%;
}

.team-bg-image {
    
    position: absolute;
    bottom: 30px;
    right: 35vw;
    transform: translateX(50%) scaleX(-1);
    height: calc(80 * var(--app-height) / 100);
    z-index: 1;
}