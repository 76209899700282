.team-members-list {
    width: auto;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    grid-template-rows: repeat(2, 1fr);
    justify-items: start;
    flex-wrap: wrap;
    /* Allow cards to wrap to the next line when there are more than 5 */
}

.team-member-card-wrapper {
    width: auto;
    height: 100%;
    flex: 0 0 calc(25% - 20px);
    /* Each card occupies 20% of the container width with a margin of 10px on each side */
    /* Add padding for spacing within each card */
    background: var(--icon-color-secondary);
    clip-path: polygon(0 25px, 25px 0, 100% 0, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0 100%);
    border-radius: 5px;
}

.team-member-card {
    margin: 4px;
    background: var(--icon-color);
    width: auto;
    height: calc(100% - 8px);
    clip-path: polygon(0 25px, 25px 0, 100% 0, 100% calc(100% - 25px), calc(100% - 25px) 100%, 0 100%);
    border-radius: 5px;
}

.team-member-image {
    margin-top: calc(10% - 5px);
    margin-left: calc(10% - 5px);
    margin-right: calc(10% - 5px);
    max-width: 80%;
    /* Ensure the image fits within the card */
    height: auto;
    /* Maintain the aspect ratio of the image */
    border: 5px solid var(--icon-color-secondary);
    border-style: double;
}

.details {
    text-align: center;
    /* Center-align text within the card */
}

.name {
    font-weight: bold;
}

.description-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10%;
    margin-right: 10%;
    /* Add margin for spacing between name and price */
}

.gallery-text-group {
    /* height: calc(85 * var(--app-height) / 100); */
    left: 12.5vw;
    position: absolute;
    top: calc(20 * var(--app-height) / 100);
    bottom: 0;
    width: 65vw;
    display: grid;
    grid-template-columns: auto auto;
    background-color: var(--icon-color-transparent);
    backdrop-filter: blur(7px) brightness(100%);
    padding: 3vw;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.team-members-list>ul {
    padding: 0;
}