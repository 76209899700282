.HomeWrapper {
    width: auto;
    height: auto;
    overflow: hidden;
}

.HomeScreensGroup {
    background: black;
    border: 1px none;
    width: 100%;
    height: 100%;
}