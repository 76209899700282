:root {
    --grid-aspect-ratio: 1.28;
    --grid-group-height: calc(60 * var(--app-height) / 100);
    --grid-group-width: calc(60 * var(--app-height) / 100 * var(--grid-aspect-ratio));
    --card-height: calc(var(--grid-group-height) * .49);
    --gap: calc(2 * var(--grid-group-height) / 100);
    --wide-card: calc((var(--grid-group-width) - var(--gap)) * (3/5));
    --narrow-card: calc((var(--grid-group-width) - var(--gap)) * (2/5));
}

.exhibits-page-desktop-wrapper {
    /*   background-image: url("./Assets/test2.jpg");
      background-size: auto calc(100 * var(--app-height) / 100);
      background-repeat: no-repeat;
      background-position: center center; */
    background-color: var(--icon-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: calc(100 * var(--app-height) / 100);
    width: 100vw;
    z-index: 0;
}

.exhibits-bg-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

/* .exhibits-page-desktop-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--icon-color-transparent);
    mix-blend-mode: color;
    z-index: 1;
} */

.exhibits-page-desktop-group {
    z-index: 2;
}

.exhibits-header-group {
    text-transform: uppercase;
    left: 5vw;
    width: 50vw;
    position: absolute;
    top: calc(5 * var(--app-height) / 100);
    white-space: nowrap;
    color: var(--icon-color);
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(4px) brightness(100%);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.exhibits-subheader {
    text-transform: none;
    left: 5vw;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
}

.card {
    cursor: pointer;
    background-image: url("./Assets/kizuScar.webp");
}

.card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--icon-color-transparent);
    mix-blend-mode: color;
    z-index: 1;
}

.opened-card {
    cursor: auto;
}

.exhibits-body-text {
    text-transform: none;
    left: 5vw;
    white-space: pre-wrap;
    padding-top: 10px;
    padding-bottom: 10px;
}

.exhibits-grid-group-container {
    position: absolute;
    bottom: calc(var(--app-height)/30 - 0.025 * var(--grid-group-height));
    left: calc(50vw - var(--grid-group-width) / 2 - 0.025 * var(--grid-group-width));
    height: calc(1.05 * var(--grid-group-height));
    width: calc(1.05 * var(--grid-group-width));
}

.exhibits-grid-group {
    position: absolute;
    bottom: calc(var(--app-height)/30);
    left: calc(50vw - var(--grid-group-width) / 2);
    height: var(--grid-group-height);
    width: var(--grid-group-width);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: var(--gap);
    box-sizing: border-box;
}

.exhibits-card-A.card {
    grid-column: 1/ span 3;
    grid-row: 1;
    position: relative;
    height: 100%;
    width: 100%;

    background-size: var(--grid-group-width) var(--grid-group-height);
    background-position: top left;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% 100%, 0 100%, 0 50px);
    z-index: 2;
}

.exhibits-card-A.opened-card {
    background: url(./Assets/sign.png) no-repeat right center var(--icon-color);
    background-size: auto 100%;
    height: var(--grid-group-height);
    width: var(--grid-group-width);
    z-index: 3;
    position: absolute;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.exhibits-card-B.card {
    grid-column: 4/ span 2;
    grid-row: 1;
    position: relative;
    height: 100%;
    width: 100%;

    background-size: var(--grid-group-width) var(--grid-group-height);
    background-position: top right;
    z-index: 2;

}

.exhibits-card-B.opened-card {
    background: url(./Assets/coffeeCup.png) no-repeat right center, var(--icon-color);
    background-size: auto 100%;
    height: var(--grid-group-height);
    width: var(--grid-group-width);
    z-index: 3;
    position: absolute;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);

}

.exhibits-card-C.card {
    grid-column: 1/ span 2;
    grid-row: 2;
    position: relative;
    height: 100%;
    width: 100%;

    background-size: var(--grid-group-width) var(--grid-group-height);
    background-position: bottom left;
}

.exhibits-card-C.opened-card {
    background: url(./Assets/door.png) no-repeat right center, var(--icon-color);
    background-size: auto 100%;
    height: var(--grid-group-height);
    width: var(--grid-group-width);
    z-index: 3;
    position: absolute;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.exhibits-card-D.card {
    grid-column: 3/ span 3;
    grid-row: 2;
    position: relative;
    height: 100%;
    width: 100%;

    background-size: var(--grid-group-width) var(--grid-group-height);
    background-position: bottom right;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.exhibits-card-D.opened-card {
    background: url(./Assets/spool.png) no-repeat right center, var(--icon-color);
    background-size: auto 100%;
    height: var(--grid-group-height);
    width: var(--grid-group-width);
    z-index: 3;
    position: absolute;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.exhibits-text-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
}

.exhibits-text-header {
    color: var(--icon-color);
    margin: auto;
    width: 100%;
    font-family: inherit;
    white-space: pre-wrap;
    position: inherit;
    background-color: rgba(255, 255, 255, 0.8);
    width: max-content;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.closed-header {
    text-align: center;
}

.exhibits-text-body {
    color: white;
    font-family: inherit;
    white-space: pre-wrap;
    display: none;
}

.xButtonBackground {
    height: 40px;
    width: 40px;
    right: 2%;
    top: 2%;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 10;
}

.exhibits-text-body {
    color: white;
    font-family: inherit;
    white-space: pre-wrap;
    display: none;
    /* Initially hidden */
}

.exhibits-open-card-text {
    margin: 5%;
    justify-content: left;
}

.exhibits-open-card-text-header {
    justify-content: left;
}

.exhibits-open-card-text-body {
    justify-content: left;
}