@media (max-width: 950px) {
    .desktopHeader {
        display: none !important;
    }
}

@media (min-width: 950px) {
    .mobileHeader {
        display: none !important;
    }
}

.header {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
/*     color: var(--icon-color-secondary); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin: 25px;
    padding: 50px;
    font-weight: normal;
    font-style: normal;
    text-transform: lowercase;
    z-index: 9998;
    transition: background-color .5s ease-in-out;
    transform-origin: top right;
/*     scale: var(--header-scale); */
    user-select: none;
}

.header>div {
    margin-right: 10px;
    margin-left: 10px;
}
.logoContainer {
    align-self: center;
}
.kizuLogo {
    background-color: white;
    width: 140px;
    height: 58px;
    -webkit-mask: url(../../Assets/KizuLogoWHiragana.svg) no-repeat center;
    mask: url(../../Assets/KizuLogoWHiragana.svg);
}

.headerItems {
    font-family: inherit;
    display: flex;
    flex-direction: column;
    color: #9058A0;
    align-self: center;
    padding-inline: 0px;
}

.headerItems>button {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    font-family: inherit;
    text-transform: uppercase;
    /* background-color: var(--header-secondary-color); */
    appearance: inherit;
    font-size: medium;
    font-weight: 500;
    height: 33px;
    width: 145px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
/*     color: var(--header-secondary-color); */
    display: grid;
/*     background: white; */
    border-radius: 12px;
    border: 3px solid transparent;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
}

.headerItems .active {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    font-family: inherit;
    text-transform: uppercase;
/*     background-color: var(--header-secondary-color); */
    appearance: inherit;
    font-size: inherit;
    font-weight: 700;
    height: 33px;
    width: 145px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    /* color: white; */
    display: grid;
    border-radius: 12px;
    border: 3px solid white;
    text-decoration: none;
    cursor: auto;
    /* box-sizing: border-box; */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
}

.headerItems .connectButton {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    /* color: white; */
    text-transform: uppercase;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
/*     background-color: var(--icon-color);
    border-color: var(--icon-color-secondary); */
    border-width: 4px;
    border-style: outset;
    height: 66px;
    width: 145px;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color .5s ease-in-out, border-color .5s ease-in-out;
}

.ethSvg {
    position: absolute;
    height: 48px;
    width: 128px;
    overflow-clip-margin: 10px;
    overflow: visible;

}

.headerItems .connectButtonMobile {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    font-family: inherit;
    text-transform: uppercase;
    appearance: inherit;
    font-size: 10px;
    font-weight: 400;
    height: 33px;
    width: 140px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
/*     color: white;
    background-color: var(--header-background-color); */
    display: grid;
    padding: 7.5px 15px;
    border-radius: 12px;
    border: 3px outset white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
}

.searchBar {
    height: 50px;
    flex: 1;
    display: flex;
    border-radius: 12px;
    align-items: center;
}

/* Add the keyframes for the expand animation */
@keyframes expand {
    from {
        width: 33px;
    }

    to {
        width: 249px;
    }
}

.header .socials {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 0;
    margin-left: 0;
}

.mobileHeader {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    height: min(calc(80 * var(--app-height) / 100), 525px);
    width: 60vw;
    display: block;
    z-index: 9998;
    pointer-events: none;
    user-select: none;
}

.mobileHeader .headerItems {
    height: calc(70 * var(--app-height) / 100);
    width: 40vw;
    padding-top: calc(5 * var(--app-height) / 100);
    padding-bottom: calc(5 * var(--app-height) / 100);
    padding-left: 10vw;
    padding-right: 10vw;
    align-content: center;
    pointer-events: auto;
    overflow: auto;
}

.mobileHeader button {
    align-self: center;
    pointer-events: auto;
}

.mobileHeader .socials {
    align-self: center;
    width: 140px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 0;
    margin-left: 0;
    pointer-events: auto;
}

.socials>p {
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    background: white;
    padding: 8px 8px;
    border-radius: 50%;
    cursor: pointer;
}

.twitterLogo {
    width: 25px;
}

.instaLogo {
    width: 25px;
}

.discordLogo {
    width: 25px;
}

.svgPath {
    transition: fill 0.5s ease-in-out;
}

nav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
}

.background {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* background: var(--icon-color-secondary); */
    pointer-events: none;
    transition: background-color .5s ease-in-out;
}

Button.hamburger {
    outline: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    z-index: 9999;
    pointer-events: auto;
}

.hamburger svg {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    stroke: var(--icon-color);
}

ul,
li {
    margin: 0;
    padding: 0;
}

.mobileHeader ul {
    padding: 25px;
    position: absolute;
    top: 0px;
    width: inherit;
}

.mobileHeader li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.hoverButtonText {
    font-size: medium;
}



