@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}