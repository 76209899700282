.home-kai-wrapper {
  background: linear-gradient(116.82deg, var(--primary-kai-color) 0%, #000000 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: calc(100 * var(--app-height) / 100);
  overflow: hidden;
}

.home-kai-group {
  height: 100%;
  width: 100%;
  z-index: 0;
}

@media (max-width: 950px) {
  .home-kai-group {
/*     background-image: url('../HomePageImg/kizuTypefaceVertical.svg');
    background-size: auto calc(98 * var(--app-height) / 100); */
    background-position: 10vw;
  }
}

/* @media (max-width: 600px) {
  .home-kai-group {
    background-position: center;
  }
} */

.home-kai-kizuLogoBackground {
  width: 85vw;
  height: var(--app-height);
  background-color: white;
  margin: auto 7.5vw;
  -webkit-mask: url(../../../Assets/KizuLogoWHiragana.svg) no-repeat center;
  opacity: 0.08;
  position: absolute;
}

@media (max-width: 950px) {
  .home-kai-kizuLogoBackground {
    transform: rotate(-90deg);
    transform-origin: bottom left;
    height: 100vw;
    bottom: calc(1* var(--app-height) / 100);
    left: 100vw;
    width: calc(98 * var(--app-height) / 100);
    margin: auto calc(1* var(--app-height) / 100);
  }
}



.home-kai-index-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.home-kai-name {
  text-align: left;
  left: 5vw;
  position: absolute;
  top: calc(15 * var(--app-height) / 100);
  width: 30vw;
  z-index: 100;
  height: calc(10 * var(--app-height) / 100);
}

@media (max-width: 950px) {
  .home-kai-name {
    top: unset;
    left: 10vw;
    bottom: calc((5 * var(--app-height) / 100) + 225px );
    height: 100px;
    width: 80vw;
    
  }
}

.home-kai-BioBoxGroup {
  font-weight: normal;
  font-style: normal;
  height: calc(25 * var(--app-height) / 100);
  left: 5vw;
  position: absolute;
  top: calc(30 * var(--app-height) / 100);
  width: 17.5vw;
  z-index: 20;
}

@media (max-width: 950px) {
  .home-kai-BioBoxGroup {
    top: unset;
    left: unset;
    bottom: calc(5 * var(--app-height) / 100);
    width: 325px;
    max-height: calc(40 * var(--app-height) / 100);
    height: 225px;
    transform: translateX(calc(50vw - 162.5px));
  }
}

.home-kai-BioBoxWithNavButtons {
  backdrop-filter: blur(7px) brightness(100%);
  -webkit-backdrop-filter: blur(6px) brightness(100%);
  background-color: var(--secondary-kai-color-transparent);
  border-radius: 10px;
  box-shadow: 0px 4px 6px #15151526;
  height: 100%;
  position: relative;
  width: 100%;
  transition: background-color 1s ease-in-out;
}

@media (max-width: 950px) {
  .home-kai-BioBoxWithNavButtons {
    max-width: 98vw;
  }
}

.home-kai-Attributes {
  color: #ffffff;
  font-family: inherit;
  font-size: 18px;
  font-weight: 200;
  left: 7.5%;
  letter-spacing: 0;
  /* line-height: 18px; */
  position: relative;
  top: 10%;
  height: 20%;
  width: 85%;
  white-space: pre-wrap;
}

.home-kai-BioText {
  color: #ffffff;
  font-size: 16px;
  font-weight: 200;
  left: 7.5%;
  letter-spacing: 0;
  /* line-height: 18px; */
  position: relative;
  top: 15%;
  width: 85%;
  height: 250px;
  max-height: 33%;
}

.home-kai-PreviousButtonWrapper {
  height: 10%;
  left: 5%;
  position: absolute;
  bottom: 10%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.home-kai-NextButtonWrapper {
  height: 10%;
  right: 5%;
  position: absolute;
  bottom: 10%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.home-kai-ButtonWrapper {
  background-color: var(--secondary-kai-color);
  border: white;
  border-color: white;
  border-width: 2px;
  border-style: outset;
  border-radius: calc(1 * var(--app-height) / 100);
  height: calc(3 * var(--app-height) / 100);
  position: relative;
  width: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .5s ease-in-out;
}

@media (max-width: 950px) {
  .home-kai-ButtonWrapper {
    height: 30px;
    width: 60px;
    
  }
}

.home-kai-PrevNextButton {
  color: #f1f0f1;
  font-family: inherit;
  font-size: 18px;
  font-weight: 300;
  left: 50%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  height: 80%;
  width: 90%;
  display: contents;
}

.home-kai-PrevNextButtonText {
  height: 80%;
  width: 90%;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.home-kai-CharacterName {
  color: #f1f0f1;
  font-family: "Headline-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 22px;
}


.home-kai-placard-group {
  position: relative;
  margin-top: calc(5 * var(--app-height) / 100);
  top:-100%;
  background-image: url('./img/KaiKanji.svg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto calc(80 * var(--app-height) / 100);
  height: calc(100 * var(--app-height) / 100);
  z-index: 10;
}

@media (max-width: 950px) {
  .home-kai-placard-group {
    background-size: 90vw calc(40 * var(--app-height) / 100);
    background-position: center 2.calc(5 * var(--app-height) / 100);
  }
}

.home-kai-character {
  position: relative;
  max-height: 95%;
  max-width: 60%;
  transform: translateX(35vw);
  max-height: calc(90 * var(--app-height) / 100);
  top: 5%;
  bottom: 5%;
  z-index: -1;
}

@media (max-width: 950px) {
  .home-kai-character {
    display: block;
    position: relative;
    max-width: 150vw;
    max-height: calc(90 * var(--app-height) / 100);
    transform: unset;
  }
}