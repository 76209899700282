.vision-page-desktop-wrapper {
/*   background-image: url("./Assets/test2.jpg");
  background-size: auto calc(100 * var(--app-height) / 100);
  background-repeat: no-repeat;
  background-position: center center; */
  background-color: var(--icon-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100 * var(--app-height) / 100);
  width: 100vw;
  z-index: 0;
}

.vision-bg-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* .vision-page-desktop-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--icon-color-transparent);
  mix-blend-mode: color;
  z-index: 1;
} */

.vision-page-desktop-group {
  z-index: 2;
}

.vision-header {
  text-transform: uppercase;
  left: 5vw;
  width: max-content;
  position: absolute;
  top: calc(5 * var(--app-height) / 100);
  white-space: nowrap;
  color: var(--icon-color);
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px) brightness(100%);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.vision-text-group {
  height: calc(50 * var(--app-height) / 100);
  left: 5vw;
  position: absolute;
  top: calc(25 * var(--app-height) / 100);;
  width: 40vw;
  display: grid;
  grid-template-columns: auto auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px) brightness(100%);
  padding: .5vw;
  clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.vision-text-grid-item {
  width: 19vw;
  height: calc(19 * var(--app-height) / 100);
}

.vision-text-header {
  color: var(--icon-color);
  font-family: inherit;
  font-size: calc(3 * var(--app-height) / 100);
  font-weight: 400;
  padding-left: 2.5vw;
/*   left: 7.5%; */
  letter-spacing: 0;
  /* line-height: 18px; */
  position: relative;
  top: 10%;
  height: 20%;
  width: 85%;
  white-space: pre-wrap;
}

.vision-text-body {
  color: var(--icon-color);
  font-family: inherit;
  font-size: calc(1.25 * var(--app-height) / 100);
  font-weight: 400;
  padding-left: 2.5vw;
/*   left: 7.5%; */
  letter-spacing: 0;
  /* line-height: 18px; */
  position: relative;
  top: 15%;
  width: 85%;
  height: 250px;
  max-height: 33%;
}