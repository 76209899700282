.musicPlayer {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    position: fixed;
    bottom: 5px;
    left: 5px;
    transform-origin: bottom left;
    scale: calc(var(--header-scale)*1);
    z-index: 1000;
}

.box {
    width: 100%;
    height: 100%;
    background: transparent;
    /* display: flex; */
    justify-content: center;
    align-items: center;
/*     filter: blur(5px) contrast(10); */
    mix-blend-mode: screen;
    box-sizing: border-box;
}

.component {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--icon-color);
/*     opacity: 0.5; */ /* <<<<<<<<<<<<<<<< OPACITY */
    width: 178px;
    height: 178px;
    margin: auto;
    padding: 10px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
}

.visualizer {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: var(--icon-color-secondary);
/*     transition: .5s; */
    transform: rotateZ(90deg);
    box-sizing: border-box;

}

.element {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    scale: 55%;
    position: absolute;
    /* background-color: yellow; */
    top: 50%;
    left: 50%;
    width: 6px;
    height: 150px;
    background-color: var(--icon-color-secondary);
/*     filter: blur(1px); */
/*     border: 1px rgb(0, 255, 34) solid; */
/*     border-radius: 10px; */
/*     border-top: 25px solid #bbbabb; */
    border-bottom: 5px solid var(--icon-color);
/*     border-right: 5px solid transparent;
    border-left: 5px solid transparent; */
    transform-origin: top left;
    box-sizing: border-box;
    transition: background-color .5s ease-in-out, color .5s ease-in-out, transform .05s;
}

.playButton {
    background: none;
    border: none;
    align-items: center;
    justify-content: center;
}

.title {
    display: flex;
    justify-content: center;
    margin-top: 0;
    color: #ffffff;
    font-weight: 600;
    font-size: calc(1.5 * var(--app-height) / 100);

}

.subTitle {
    display: flex;
    justify-content: center;
    margin-top: 0;
    color: #ffffff;
    font-weight: 200;
    font-size: calc(1 * var(--app-height) / 100);
}

.time {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 5px;

}

.time>p {
    font-weight: 200;
    font-size: calc(0.66 * var(--app-height) / 100);
}

.timeline {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #27ae60;
}

input[type="range"] {
    -webkit-appearance: none;
    align-items: center;
    width: 100%;
    background: transparent
}

input[type='range']::-webkit-slider-thumb {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background-color: var(--icon-color-secondary);
    cursor: pointer; 
    border-radius: 50%;
    margin-top: -7.5px
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 5px;
    cursor: pointer;
    background-color: white; 
    border-radius: 3px;
    margin-top: 10px; 
    margin-bottom: 10px;
  }

@media (max-width: 950px) {
    .musicPlayer {
        visibility: hidden;
    }
}