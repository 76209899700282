.nftList {
  display: grid;
  grid-template-columns: repeat(5, 12.5vw);
  grid-template-rows: 18vw;
  justify-items: start;
  flex-wrap: wrap; /* Allow cards to wrap to the next line when there are more than 5 */
  gap: 0px; /* Adjust the gap between the cards as needed */
}

.collection-card {
  width: 11vw;
  height: 16vw;
  flex: 0 0 calc(20% - 20px); /* Each card occupies 20% of the container width with a margin of 10px on each side */
  border: 1px solid #ccc; /* Add a border for better visualization (you can customize this) */
  padding: 10px; /* Add padding for spacing within each card */
  margin: 20px; /* Add margin for spacing between cards */
}

.collection-card img {
  max-width: 100%; /* Ensure the image fits within the card */
  height: auto; /* Maintain the aspect ratio of the image */
}

.details {
  text-align: center; /* Center-align text within the card */
}

.name {
  font-weight: bold;
}

.id {
  color: #777;
}

.priceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px; /* Add margin for spacing between name and price */
}

.ethImage {
  width: 20px; /* Adjust the width of the ETH image as needed */
  height: auto; /* Maintain the aspect ratio of the image */
  margin-right: 5px; /* Add margin for spacing between the image and price */
}

.price {
  font-weight: bold;
  color: #333; /* Customize the color of the price text */
}
