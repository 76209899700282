.preloaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  background-color: #000000;
  z-index: 9999;
  opacity: 1;
  transition: opacity 1s; /* Add transition for the opacity property */
}

.preloader {
  font-size: 24px;
  font-weight: bold;
}

.preloaderWrapper {
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 100vw;
}

.videoContainer {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.videoContainer video {
  width: 100%;
  height: 100%;
  max-width: 75vw;
  object-fit: contain;
}
