/*.home-index-wrapper {
    position: relative;
    overflow: hidden;
}*/

.hidden {
  display: none;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-prev {
  left: 30% !important;
  top: unset !important;
  bottom: 1% !important;
  z-index: 11 !important;
}

.swiper-button-next {
  right: 30% !important;
  top: unset !important;
  bottom: 1% !important;
  z-index: 11 !important;
} 

@media (max-width: 950px) {
  .swiper-button-next {
      visibility: hidden;
  }
  .swiper-button-prev {
      visibility: hidden;
  }
}



/* .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */


/* .page-transition-container {
  position: relative;
  height: calc(100 * var(--app-height) / 100);
}

.page-transition-container>div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
} */

/*
  .page-transition-enter {
    position: absolute;
  }
  
  .page-transition-enter-active {
    position: absolute;
  }
  
  .page-transition-exit {
    position: absolute;
  }
  
  .page-transition-exit-active {
    position: absolute;
  }

*/