.gallery-page-wrapper {
    background-color: var(--icon-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: calc(100 * var(--app-height) / 100);
    width: 100vw;
    z-index: 0;
}

.gallery-header {
    text-transform: uppercase;
    left: 5vw;
    position: absolute;
    top: calc(5 * var(--app-height) / 100);
    white-space: nowrap;
    color: var(--icon-color);
    background-color: rgba(255, 255, 255, .8);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
}

.gallery-text-group {
    /* height: calc(85 * var(--app-height) / 100); */
    left: 12.5vw;
    position: absolute;
    top: calc(20 * var(--app-height) / 100);
    bottom: 0;
    width: 65vw;
    display: grid;
    grid-template-columns: auto auto;
    background-color: var(--icon-color-transparent);
    backdrop-filter: blur(7px) brightness(100%);
    padding: 3vw;
    clip-path: polygon(0 50px, 50px 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
    overflow-y: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.gallery-text-group::-webkit-scrollbar {
    display: none;
}

