.footer {
    /* will-change: color, background-color, border-color, transform, scale, opacity, visibility; */
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    position: absolute;
    bottom: 0;
    right: 0;
/*     color: var(--icon-color-secondary); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-style: normal;
    
    transition: background-color .5s ease-in-out;
    transform-origin: top right;
    user-select: none;
}

.footer-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    bottom: 0;
    right: 0;
}

.theme-tab-container {
    border-radius: 20px;
    padding: 5px;
    display: flex;
    align-content: center;
    align-items: center;
/*     justify-content: center; */
    margin: 20px;

    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9998;
}

.theme-tab {
    height: 15px;
    position: relative;
    padding: 8px;
    font-size: 15px;
    font-weight: 800;
    line-height: 15px;
    cursor: pointer;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 9999;
    color: var(--icon-color);
    background-color: var(--icon-color-secondary);
}

.tab {
    height: 15px;
    position: relative;
    padding: 8px;
    margin: 0;
    font-size: 15px;
    font-weight: 800;
    line-height: 15px;
    cursor: pointer;
    z-index: 9999;
    width: 55px;
    text-align: center;
    border-radius: 15px;
}

.selection {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.confirmation-dialog-background {
    width: 300px;
    flex: 0 0 100px;
    position: relative;
    bottom: 70px;
    right: 10px;
    border-radius: 10px;
    background-color: var(--icon-color-transparent);
    z-index: 9998;
}

button.close {
    position: absolute;
    top: 10px;
    right: 10px;
    stroke: var(--icon-color);
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 9999;
  }

.confirmation-dialog-text {
    position: absolute; 
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    font-size: medium;
    font-weight: 700;
    color: white;
    z-index: 9999;
}

.ok {
    height: max-content;
    width: 60px;
    position: absolute;
    padding: 8px;
    font-size: 15px;
    font-weight: 800;
    line-height: 15px;
    cursor: pointer;
    border-radius: 15px;
    z-index: 9999;
    color: var(--icon-color);
    background-color: var(--icon-color-secondary);
    bottom: 10px;
    right: 15px;
    text-align: center;
}